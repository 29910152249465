<template>
  <div>
     <!-- banner start -->
    <section class="banner_sec we_love">
      <div class="banner_hed">
        <h1 class="heading_2">We’d Love to Connect</h1>
      </div>
    </section>
  
    <!-- heading start -->
    <section class="pt_10 heading_sec right_left_text_sec learn_more pb-0">
      <div class="container">
        <div class="row justify-content-center border-bottom-l">
          <div class="col-xl-10 col-lg-12">
            <div class="">
              <div class="heading_box webinars_heading_banner">
                <h3 class="heading_3">We’d love to help craft the perfect program for your needs.</h3>
              </div>
              <div class="web_arrow">
              <a v-scroll-to="{el: '#calender',easing: 'linear',offset: -400,}">
                <img src="../../assets/home/images/webinars/arrow.svg" alt="img">
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->
    <div class="calender" id="calender">
      <div class="container">
        <div class="row align-items-start border-bottom-l mb_100">
          <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto">
            <div class="heading_box webinars_heading_banner pb_20">
                <h3 class="heading_3"> Let’s Chat!</h3>
              </div>
            <!-- ScheduleOnce embed START -->
              <div id="SOIDIV_VivienneFleischer" data-so-page="VivienneFleischer" data-height="550" data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div>
           
  
            <!-- ScheduleOnce embed END -->
  
          </div>
          <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-auto ipdatop">
              <div class="heading_box webinars_heading_banner d-flex align-items-center justify-content-center">
                <h3 class="heading_custom text-center"> </h3>
              </div>
          </div>
          <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto ipdatop">
             <div class="heading_box webinars_heading_banner pb_20">
                <h3 class="heading_3"> Or, Drop us a line  </h3>
              </div>
            <!-- <img src="../../assets/home/images/learn-more/calender.png" alt=""> -->
            <iframe  src="https://survey.alchemer.com/s3/7270906/PBergo-com-Contact-Form" height="596px" width="100%"></iframe>
            
          </div>
        </div>
      </div>
    </div>
    <section class="contact-us mb_100">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-xl-12 col-lg-12">
              <div class="row justify-content-center">
                <div class="col-lg-12 col-xl-12 text-center">
                  <div class="align-items-center contact_heading d-flex justify-content-center small_Center">
                    <img src="../../assets/home/images/learn-more/phone.png" alt="" class="mr_20">
                    <h3 class="heading_5">Call Us</h3>
                  </div>
                  <div class="details small-center">
                    <div class="phone">
                      <h4> <strong>Phone:</strong>  <a href="tel:(510) 839-5716">(510) 839-5716</a></h4>
                      <h4> <strong>Fax: </strong>   <a href="fax:(510) 839-4813">   (510) 839-4813</a></h4>
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
        
      </div>
    </section>
    <!-- were available start -->
    <section class=" pt_80 pb_70 bg_primary testimonial learnMore">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <carousel :margin="24" :nav="true" :loop="true" :navText="['&laquo;','&raquo;']" :dots="false" :items="1" class="owl-slider">     
                    <div class="item">
                        <h3>“Ergo-Ally transformed our lives. We were able to have total continuity of business and our
                      employees remained healthy and productive!”
                        </h3>
                        <div class="author">- Atlassian </div>
                    </div>
                    <div class="item">
                        <h3>“Ergo-Ally brings data to Ergonomics, allowing us to better manage risk in a distributed workforce
                          and easily educate our global population on best practices to stay safe and productive when working”
                        </h3>
                        <div class="author">- Splunk </div>
                    </div>
                    <div class="item">
                        <h3>"Ergo-Ally’s intuitive application has allowed users to correct such things as their posture,
                          screen alignment and so forth all within the comfort of their home or office.” 
                        </h3>
                        <div class="author">- Twitch </div>
                    </div>
              </carousel>
          </div>
        </div>
      </div>
    </section>
  </div>
  </template>
  <script>
  import "vue-select/dist/vue-select.css"
  import carousel from "vue-owl-carousel"
  export default {
      name: 'learn_more',
      created() {
      var scripts = [
        "https://cdn.scheduleonce.com/mergedjs/so.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
      components: { carousel },
      data() {
        return {
          navText: ["Prev", "Next"],
        responsive: { 0: { items: 1, nav: false }, 600: { items: 3, nav: true } }
      };
    },
    mounted(){
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    }
  }
  </script>